@import "../../../baseStyles";

.sliderColor {
  font-family: "Avenir-Light" !important;
  color: $jade-blue !important;
}

.sliderLabel {
  font-family: "Avenir-Light" !important;
  color: $matte-black !important;
}
