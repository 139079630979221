@import "../../../baseStyles";

.header {
  font-family: "Avenir-Black";
  font-size: 24px;
  margin-top: 2%;
  margin-bottom: 8%;

  @media all and (min-width: $breakpoint-large-tablet) {
    margin-bottom: 2%;
  }
}

.collegeList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.college {
  font-family: "Avenir-Roman";
  color: $white;
  background-color: $jade-blue;
  text-align: center;
  border: 1px solid $white;
  width: 400px;
}

.loader,
.algorithmError {
  @extend .collegeList;
  margin: 20% 0;
}

.loaderLabel {
  margin-top: 1%;
}

.algorithmErrorText {
  font-family: "Avenir-Heavy";
  font-size: 18px;
  text-align: center;
}
