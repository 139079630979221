@import "../../baseStyles";

.container {
  width: 100%;
}

.landingContainer {
  width: 100%;
  height: 500px;
  background-color: $matte-black;

  @media all and (min-width: $breakpoint-small-tablet) {
    height: 700px;
  }
}

.processContainer {
  width: 100%;
}

.dataContainer {
  width: 100%;
  background-color: $matte-black;
}

.aboutContainer {
  width: 100%;
}

.tickerContainer {
  position: absolute;
  width: 100%;
  height: 500px;

  @media all and (min-width: $breakpoint-small-tablet) {
    height: 700px;
  }
}

.landingContentContainer {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  color: $white;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.header {
  margin-top: 7%;
  font-family: "Avenir-Black";
  font-size: 40px;
  color: $jade-blue;
  text-align: center;
  padding: 0 20px;

  @media all and (min-width: $breakpoint-large-tablet) {
    padding: 0;
    font-size: 64px;
  }
}

.lineBreak::before {
  @media all and (min-width: $breakpoint-large-tablet) {
    content: "\A";
    white-space: pre;
  }
}

.tagline {
  display: none;

  @media all and (min-width: $breakpoint-small-tablet) {
    display: initial;
    margin-top: 10%;
    font-family: "Avenir-Book";
    font-size: 24px;
    text-align: center;
  }

  @media all and (min-width: $breakpoint-desktop) {
    display: initial;
    margin-top: 3%;
  }
}

.hook {
  margin-top: 20%;
  font-family: "Avenir-Heavy";
  font-size: 24px;
  color: $code-green;
  text-align: center;

  @media all and (min-width: $breakpoint-small-tablet) {
    margin-top: 10%;
  }

  @media all and (min-width: $breakpoint-desktop) {
    margin-top: 4.5%;
  }

  @media all and (min-width: $breakpoint-large-desktop) {
    margin-top: 1.5%;
  }
}

.button {
  margin-top: 15%;

  @media all and (min-width: $breakpoint-small-tablet) {
    margin-top: 10%;
  }

  @media all and (min-width: $breakpoint-desktop) {
    margin-top: 5%;
  }
}

.section {
  width: 100%;
  padding-bottom: 5%;

  @media all and (min-width: $breakpoint-small-tablet) {
    padding-bottom: 0%;
  }
}

.gridContainer {
  padding: 10%;
  margin-left: 2%;

  @media all and (min-width: $breakpoint-desktop) {
    padding: 5%;
  }
}
