@import "../../baseStyles";

.container {
  width: 100%;
  height: 100%;

  @media all and (max-width: $breakpoint-small-tablet) {
    margin: 10% 0%;
  }
}

.progressContainer {
  width: 200px;
  margin: auto;
  padding: 5% 10%;

  @media all and (min-width: $breakpoint-small-tablet) {
    width: 350px;
  }
}

.formContainer {
  margin: 20% 10%;
  margin-bottom: 0;

  @media all and (min-width: $breakpoint-small-tablet) {
    margin: 1% 15%;
    margin-bottom: 0;
  }
}

.buttonListContainer {
  margin: 20% 0%;
  text-align: center;

  @media all and (min-width: $breakpoint-large-tablet) {
    margin: 5% 0%;
  }
}

.buttonContainer {
  margin-left: 2%;
}
