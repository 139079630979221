@import "../../baseStyles.scss";

.button {
  background-color: $jade-blue;
  color: $white;
  font-family: "Avenir-Black";
  font-size: 24px;
  padding: 7px 40px;
  cursor: pointer;
  transition: 0.3s;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.button:hover {
  background-color: $code-green;
}

.disabled {
  cursor: default;
  opacity: 0.5;

  &:hover {
    background-color: $jade-blue;
  }
}
