@import "../../baseStyles";

.stepContainer {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: $matte-black;
  border: 5px solid $white;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.stepLabel {
  margin-left: -27%;
  text-align: center;
}

.preferencesLabel {
  margin-left: -100%;
}

.accomplishedLabel {
  font-family: "Avenir-Black";
}

.checkpoint {
  margin-bottom: 185%;
}

.accomplishedCheckpoint {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  margin-bottom: 90%;
  background-color: $jade-blue;
}

.completed {
  background-color: $code-green;
}

.clickable {
  cursor: pointer;
}
