// Site Colors
$jade-blue: #6484f5;
$code-green: #4feb97;
$eggshell: #fdf8f8;
$matte-black: #333333;
$white: #ffffff;

// Breakpoints
$breakpoint-mobile: 320px;
$breakpoint-small-tablet: 768px;
$breakpoint-large-tablet: 956px;
$breakpoint-desktop: 1024px;
$breakpoint-large-desktop: 1368px;
