@import "../../../baseStyles";

.formFields {
  color: $matte-black;
}

.fieldHeader {
  font-family: "Avenir-Heavy";
  font-size: 24px;
}

.questionSection {
  padding: 3% 0;
}

.sliderContainer {
  margin: 0 5%;
  margin-top: 7%;
}

.areaTypeContainer {
  margin-top: 4%;
}

.locationContainer {
  margin-left: 3%;
  margin-top: 8%;
}

.fieldDescription {
  font-family: "Avenir-Roman";
  margin-top: 1%;
  margin-bottom: 5%;

  @media all and (min-width: $breakpoint-large-tablet) {
    margin: 1% 0;
  }
}
